html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  .container {
    min-height: 15vh;
    overflow-y: auto;
  }
  
  .fixed-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  