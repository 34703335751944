html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  .container {
    min-height: 16vh;
    overflow-y: auto;
  }
  
  .fixed-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    
  }
  
  .sticky-saldo {
    position: sticky;
    top: 60px; /* Asegura que se quede debajo del navbar */
    z-index: 1000;
    width: 100%;
  }
  