.custom-table {
    border-collapse: collapse;
    width: 100%;
    
  }
  .custom-table thead th {
    border-bottom: 1px solid #ddd;
  }
  .custom-table tbody tr {
    transition: background-color 0.3s ease;
  }
  .custom-table tbody tr:hover {
    background-color: #f1f1f1;
  }
  .custom-table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
  .custom-table tbody tr:nth-child(even) {
    background-color: #ffffff;
  }
  .custom-table td,
  .custom-table th {
    border: none;
    padding: 12px 15px;
  }
  .mobile-cards {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  .mobile-card {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    transition: background-color 0.3s ease;
  }
  .deleted-row {
    background-color: #ffcccc;
  }
  .mobile-row {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
  }
  .mobile-row strong {
    flex: 1;
    text-align: left;
  }
  @media (min-width: 768px) {
    .mobile-cards {
      display: none;
    }
  }
  